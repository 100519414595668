.LoginTextDown {
    padding: 0.5rem;
    width: 100%;
    text-align: center;
    margin: 2rem auto;
}


.PinInputClass {
    width: 40px;
    padding: 0.75rem;
    text-align: center;
    border: none;
    outline: 1px solid rgba(22, 29, 57, 0.329);
    border-radius: 0.5rem;
}

.PinInputClass:focus{
    outline: 1.5px solid var(--color10)
}


.Container-Pagamento svg path{
    color: var(--color10);
}

.disclaimerContributo b{
    font-weight: 800;
    color: var(--color10);
}