.AbbonamentoTC * {
    color: rgb(98, 98, 98);
    font-size: 13px;
    text-align: justify;
}

.AbbonamentoTC p {
    margin: 0.5rem 2rem;
}

.AbbonamentoTC h2 {
    margin: 2rem 2rem 1rem 2rem;
}



.RiattivaAbbonamento {
    display: flex;
    flex-flow: row wrap;
    justify-content: start;
    align-items: center;
    margin: 0.5rem 0.5rem 0.5rem 0;
    background: var(--color10);
    padding: 0.25rem 0.5rem;
    color: white;
    font-size: 12px;
    font-weight: 600;
    border-radius: 0.5rem;
    width: fit-content;
    text-decoration: none;
    margin: 0 auto 5rem auto;
}



.LineThrough {
    /*display: inline-block;
    position: relative;
    padding: 0.2rem;*/
    text-decoration: line-through var(--color9);
}

.LineThrough::before {

   /* content: '';
    position: absolute;
    left: -0.1em;
    right: -0.1em;
    top: 0.38em;
    bottom: 0.38em;
    background: linear-gradient(to left top, transparent 45.5%, currentColor 47.5%, currentColor 52.5%, transparent 54.5%);
    pointer-events: none;*/
}